<template>
    <div class="category-posts">
        <div class="container">
            <div class="posts">
<!--                <div class="post" v-for="(post, index) in posts">-->
<!--                    <figure class="post-image">-->
<!--                        <img :src="post.featured_image" :alt="post.title">-->
<!--                    </figure>-->
<!--                    <h2 v-text="post.title"></h2>-->
<!--                    <p v-text="post.excerpt"></p>-->
<!--                    <span v-html="formatDate(post.created_at)"></span>-->
<!--                    <a :href="post.slug" :title="post.title" class="i-btn white" v-text="messages[locale].readMore"></a>-->
<!--                </div>-->
                <div v-for="(post, index) in posts" :key="index" class="blog-cell">
                    <a :href="`${post.slug}`" class="project">
                        <div class="image">
                            <img :src="post.featured_image" :alt="post.title">
                        </div>
                        <div class="c-project-text-holder">
                            <p class="post-title" v-text="post.title"></p>
                            <p class="post-date" v-text="formatDate(post.created_at)"></p>
                        </div>
                    </a>

                </div>
            </div>
            <div class="rm-container">
                <a @click="loadMore" :class="buttonClasses" v-text="messages[locale].loadMore"></a>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from "moment";
    export default {
        props: ['items', 'category_id', 'override'],
        data() {
            return {
                isLoading: false,
                offset: 6,
                shouldLoadFurther: true,
                posts: null,
                category: null,
                defaultOffset: 6,
                locale: window.user.lang,
                messages: {
                    bg: {
                        readMore: "Прочети повече",
                        loadMore: "Зареди още",
                        noMore: "Няма повече публикации в тази категория!"
                    },
                    en: {
                        readMore: "Read more",
                        loadMore: "Load more",
                        noMore: "There are no more posts in this category!"

                    }
                }
            }
        },
        created() {
            moment.locale(this.locale);
        },
        mounted() {
            if(this.override) {
                this.offset = 12
                this.defaultOffset = 12
            }

            if(typeof this.category_id === 'undefined') {
                this.category = 0
            } else {
                this.category = this.category_id
            }

            this.posts = this.items
        },
        methods: {
            loadMore() {
                let that = this
                if(this.shouldLoadFurther) {
                    this.isLoading = true
                    axios.get(`/load-posts`, {
                        params: {
                            category_id: this.category,
                            offset: this.offset
                        }
                    }).then((response) => {
                        that.isLoading = false
                        if(typeof response.data === 'string') {
                            that.$buefy.snackbar.open(this.messages[this.locale].noMore)
                            that.shouldLoadFurther = false
                        } else {
                            that.offset = that.offset + that.defaultOffset
                            that.posts = that.posts.concat(response.data)
                        }
                    }).catch(function (error) {
                        console.log(error);
                    });
                } else {
                    that.$buefy.snackbar.open(this.messages[this.locale].noMore)
                }
            },
            formatDate(date) {
                return moment(date).fromNow()
            }
        },
        computed: {
            buttonClasses() {
                return this.isLoading ? 'button i-btn is-loading' : 'button i-btn'
            }
        }
    }
</script>
