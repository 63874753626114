<template>
    <div>
        <div v-if="slideshow" class="post-carousel">
            <div v-for="(image, index) in images" :key="index" class="carousel-cell">
                <div class="image">
                    <img :src="image" :alt="post.title">
                </div>
            </div>
        </div>
        <div v-else>
            <div class="image">
                <img :src="post.full_url" :alt="post.title">
            </div>
        </div>
    </div>
</template>

<script>

import Flickity from 'flickity';
import 'flickity/dist/flickity.min.css';

export default {

    props: ['post'],

    data() {
        return {
            flickity: false,
            slideshow: false,
            //testing
            images: {}
        }
    },
    created() {
        // this.images = {
        //     0: '/images/projects/lyulin5/lyulin_map.jpg',
        //     1: '/images/projects/lyulin5/lyulin_map.jpg',
        // }

        if(this.post_slideshow_images) {
            this.images = JSON.parse(this.post.slideshow_images)
        } else {
            this.images = {
                0: this.post.full_url
            }
        }

        if (Object.keys(this.images).length > 1) {
            this.slideshow = true;
        }
    },
    mounted() {
        if (this.slideshow) {
            this.flickity = new Flickity('.post-carousel', {
                "cellAlign": "left",
                "contain": true,
                "wrapAround": true,
                "pageDots": false,
                "autoPlay": false,
                "setGallerySize": false
            })
        }
    },
    methods: {},
}
</script>

<style scoped>

</style>
