
require('./bootstrap');
require('./scripts');
//require('./smooth');

window.$ = window.jQuery = require('jquery');

import Vue from 'vue'
import Buefy from 'buefy'
import IndexPostCarousel from './components/IndexPostCarousel'
import SingleProjectCarousel from './components/SingleProjectCarousel'
import PostCarousel from './components/PostCarousel'
import Newsletter from './components/Newsletter'
import Posts from './components/Posts'
import Accordion from './components/FrontEndAccordion'
import Modal from 'vue-js-modal';
// window.moment = require('moment');
// window.lightbox = require('lightbox2');

Vue.component('index-post-carousel', IndexPostCarousel);
Vue.component('single-project-carousel', SingleProjectCarousel);
Vue.component('post-carousel', PostCarousel);
Vue.component('newsletter', Newsletter);
Vue.component('posts', Posts);
Vue.component('accordion', Accordion);


Vue.use(Buefy, {
    defaultIconPack: 'fal'
});
//
Vue.use(Modal);

const app = new Vue({
    el: '#app',
    methods: {
        closeAllOtherAccordions(id) {
            for(let key in this.$refs) {
                    if(this.$refs[key].id !== id) {
                        this.$refs[key].isOpen = false;
                    }
            }
        }
    }
});
