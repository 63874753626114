

$(document).ready(function () {
    $('body').removeClass('fade-out')
    $('#loader').removeClass('visible')

    $(".mobile-toggle").click(function () {
        toggleMenu()
    });

    $('.delete').on('click', function(){
        $('.session-holder').fadeOut();
    });

    if($('.session-holder').length !== 0) {
        setTimeout(function() {
            $('.session-holder').fadeOut();
        }, 5000)
    }

    $(document).on('click', function(event) {
        if(event.target.classList.contains('mobile-menu-container') &&
            event.target.classList.contains('active')) {
            toggleMenu();
        }
    })

    $("a").on('click', function (event) {
        if (this.hash !== "") {
            event.preventDefault();
            var hash = this.hash;
            $('html, body').animate({
                scrollTop: $(hash).offset().top
            }, 800, function () {
                window.location.hash = hash;
            });
        }
    });

    function toggleMenu() {
        $(".mobile-menu-container").toggleClass("active");
    }

});




