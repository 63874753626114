<template>
    <div class="container mx-auto">
        <div class="ribbon newsletter">
            <b-loading :isFullPage="true" :active.sync="isLoading"></b-loading>
            <img :src="logoImage" alt="DPS">
            <p v-text="messages[locale].subscribe"></p>
            <div class="input-container">
                <input v-model="email" type="text" :placeholder="messages[locale].your_email" class="input">
                <button @click="submitForm()" class="ribbon-btn" v-text="messages[locale].sign"></button>
            </div>

            <label class="checkbox">
                <input v-model="consent" type="checkbox">
                {{ messages[locale].consent }}
            </label>


        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            email: null,
            consent: false,
            isLoading: false,
            errors: {},
            locale: window.user.lang,
            messages: {
                bg: {
                    your_email: "Вашият имейл",
                    consent: "Съгласен съм с политиката за поверителност на Младежко ДПС",
                    subscribe: "Абонирайте се за нашия информационен бюлетин!",
                    sign: "Запиши се",
                    error_consent: "Трябва да се съгласите с политиката за поверителност!",
                    error_email: "Моля въведете валиден имейл адрес!"
                },
                en: {
                    your_email: "Your e-mail",
                    consent: "I agree with the privacy policy of the Youth MRF",
                    subscribe: "Sign up for our newsletter",
                    sign: "Sign up",
                    error_consent: "You must agree to our privacy policy!",
                    error_email: "Please enter a valid e-mail address!"
                }
            }
        }
    },
    methods: {
        submitForm() {

            if (!this.consent) {
                this.$buefy.toast.open({
                    message: this.messages[this.locale].error_consent,
                    type: "is-danger"
                })
            } else {
                this.isLoading = true;

                let data = new FormData();

                data.append('email', this.email)
                data.append('consent', this.consent)

                axios({
                    method: "POST",
                    url: "/subscribe",
                    data: data
                }).then(response => {
                    this.email = null
                    this.consent = false
                    this.isLoading = false
                    this.$buefy.toast.open({
                        message: response.data,
                        type: 'is-success'
                    })
                }).catch(() => {
                    this.isLoading = false
                    this.$buefy.toast.open({
                        message: this.messages[this.locale].error_email,
                        type: 'is-danger'
                    })
                })
            }
        }
    },
    computed: {
        logoImage() {
            return '/webp/dps_'+this.locale+'.webp'
        }
    }
}
</script>

<style scoped>

</style>
