<template>
    <div class="post-carousel">
        <div v-for="(post, index) in posts" :key="index" class="carousel-cell">
            <a :href="`${post.slug}`" class="project">
                <div class="image">
                    <img :src="post.thumb" :alt="post.title">
                </div>
                <div class="c-project-text-holder">
                    <p class="post-title" v-text="post.title"></p>
                    <p class="post-date" v-text="dateFormat(post.created_at)"></p>
                </div>
            </a>

        </div>
    </div>
</template>

<script>

import Flickity from 'flickity';
import 'flickity/dist/flickity.min.css';
import moment from 'moment';

export default {

    props: ['posts', 'locale'],

    data() {
        return {
            flickity: false,
        }
    },
    created() {
        moment.locale(this.locale);
    },
    mounted() {
        this.flickity = new Flickity('.post-carousel', {
            "cellAlign": "left",
            "contain": true,
            "wrapAround": true,
            "pageDots": true,
            "autoPlay": false,
            "setGallerySize": false
        })
    },
    methods: {
        dateFormat(date) {
            return moment(date).format('ll');
        }
    }


}
</script>

<style scoped>

</style>
