<template>

    <div class="fe-accordion">
        <div @click="toggle" class="question" :class="classes">
            <slot name="header"></slot><i class="fas fa-chevron-down"></i>
        </div>
        <div class="answer" :class="classes">
            <slot name="content"></slot>
        </div>
    </div>

</template>

<script>
    export default {
        props: ['data', 'id'],

        data() {
            return {
                isOpen: false,
                content: null
            }
        },
        created() {
            this.content = this.data
        },
        methods: {
            toggle() {
                this.isOpen =! this.isOpen
                this.$root.closeAllOtherAccordions(this.id)
            }
        },
        computed: {
            classes() {
                return this.isOpen ? 'is-open' : ''
            }
        }
    }
</script>

<style scoped>

</style>
