<template>
    <div class="sp-carousel">
        <div v-for="(image, index) in images" :key="index" class="carousel-cell">
            <div class="image">
                <img :src="image" alt="Noblehouse">
            </div>
        </div>
    </div>
</template>

<script>

import Flickity from 'flickity';
import 'flickity/dist/flickity.min.css';

export default {

    props: ['images'],

    data() {
        return {
            flickity: false,
            //testing
        }
    },
    created() {
        // this.images = {
        //     0: '/images/projects/lyulin5/lyulin_map.jpg',
        //     1: this.project.main_map_image,
        //     2: this.project.main_map_image,
        //     3: this.project.main_map_image,
        //     4: this.project.main_map_image
        // }
    },
    mounted() {
        this.flickity = new Flickity('.sp-carousel', {
            "cellAlign": "left",
            "contain": true,
            "wrapAround": true,
            "pageDots": false,
            "autoPlay": false,
            "setGallerySize": false
        })
    },
    methods: {},
}
</script>

<style scoped>

</style>
